var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{staticClass:"required",attrs:{"disabled":_vm.disabled,"label":_vm.$t('label.groups'),"items":_vm.items,"persistent-hint":_vm.disabled,"hint":_vm.disabled ?_vm.$t ('label.disabledHint') : '',"item-text":"name","item-value":"id","error-messages":_vm.errorMessages,"multiple":"","clearable":""},on:{"blur":_vm.blur},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var parent = ref.parent;
return [_c('v-chip',{key:item.id,staticClass:"my-1",attrs:{"close":!item.disabled && !_vm.disabled,"outlined":"","color":"black"},on:{"update:active":function($event){parent.selectItem (item)}}},[_c('span',{staticClass:"chipContent",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":false}},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectedGroups.includes(item.id) ? 'primary' : 'default'}},[_vm._v(" "+_vm._s(_vm.value.includes(item.id) ? 'check_box' : 'check_box_outline_blank')+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}})],1),_c('v-list-item-action',{on:{"click":function($event){$event.preventDefault();return (function (e){ return e.stopPropagation(); })($event)},"input":function($event){$event.preventDefault();return (function (e){ return e.stopPropagation(); })($event)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on2 = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.setDialog([item.id], [item.name])}}},on2),[_vm._v(" info ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t ('label.click'))+" ")])],1)],1)]}},(!_vm.disabled)?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.setDialog(_vm.selectedGroups, _vm.selectedNames)}}},on),[_vm._v(" info ")])]}}],null,false,521519665)},[_vm._v(" "+_vm._s(_vm.$t ('label.click'))+" ")]),_c('base-dialog',{attrs:{"scrollable":"","mw2":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('client-group-info-card',{attrs:{"groups":_vm.dialogGroups,"names":_vm.dialogNames},on:{"close":function($event){_vm.dialog = false}}})],1)]},proxy:true}:null],null,true),model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}})}
var staticRenderFns = []

export { render, staticRenderFns }