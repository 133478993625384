<messages>["./ClientCreateUpdate"]</messages>

<template>
  <base-dialog
    v-model="dialog"
    scrollable
    mw0>
    <template #activator="{ on: dia }">
      <v-tooltip top>
        <template #activator="{ on: tooltip }">
          <v-icon v-on="{...dia, ...tooltip}">
            info
          </v-icon>
        </template>
        {{ $t ('label.click') }}
      </v-tooltip>
    </template>
    <services-info-card :options="mappedOptions" @close="dialog=false"/>
  </base-dialog>
</template>

<script>
  import BaseDialog from '@/app/core/components/BaseDialog'
  import ServicesInfoCard from './ServicesInfoCard'

  export default {
    components: {
      BaseDialog,
      ServicesInfoCard
    },

    props: {
      options: {
        type: Array,
        default: () => ([])
      },
      value: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      mappedOptions () {
        return this.options.map ((i) => i.value ? i.value : i)
      }
    }
  }
</script>
