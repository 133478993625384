<messages>["./ClientCreateUpdate"]</messages>

<template>
  <v-row key="hubscheduledbasic">
    <v-col cols="12">
      <date-picker-field
        v-model="internalStartDate"
        clearable
        type="month"
        :allowed-dates="future"
        :disabled="isProfile"
        :label="$t('label.futureVatStart')"
        :error-messages="validationErrors(
          'dateValidator',
          { required: 'required.vatStartDate' })"
        @blur="v.dateValidator.$touch()"/>
    </v-col>

    <v-col cols="12">
      <v-switch
        v-model="internalSetVatData">
        <template #label>
          <v-tooltip top>
            <template #activator="{on}">
              {{ $t('subsection.scheduledVat.setData') }}
              <v-icon class="ml-3" v-on="on">
                info
              </v-icon>
            </template>
            <span>{{ $t('subsection.scheduledVat.setDataInfo') }}</span>
          </v-tooltip>
        </template>
      </v-switch>
    </v-col>

    <template v-if="setVatData">
      <v-col
        cols="12" sm="6">
        <v-text-field
          v-model.trim="internalTaxInfo.vatRegime"
          :disabled="isProfile"
          :label="$t('label.futureVatRegime')"/>
      </v-col>

      <v-col
        cols="12" sm="6">
        <v-text-field
          v-model.trim="internalTaxInfo.vatId"
          :disabled="isProfile"
          :label="$t('label.futureVatId')"/>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model.trim="internalTaxInfo.vatMode"
          :items="vatModeOptions"
          :disabled="isProfile"
          clearable
          :label="$t('label.futureVatMode')"
          :error-messages="validationErrors(
            'taxValidator.vatMode',
            { required: 'required.vatMode' })"
          @blur="v.taxValidator.vatMode.$touch()"/>
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model.trim="internalTaxInfo.vatInvoiceText"
          :disabled="isProfile"
          rows="1"
          :label="$t('label.futureInvoiceText')"/>
      </v-col>

      <v-col cols="12">
        <v-row
          v-for="(taxRates, idx) in rates"
          :key="taxRates.id">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.trim="taxRates.name"
              :label="$t('label.futureVatRateName')"
              :disabled="isProfile"
              :class="{required: internalTaxInfo.taxRates.length !== 1}"
              :error-messages="vatRateLabelErrors(v.taxValidator.taxRates, idx)"
              @blur="v.taxValidator.taxRates.$each[idx].name.$touch()"/>
          </v-col>

          <v-col
            :class="vatRateClasses (
              rates, !isProfile)">
            <v-text-field
              v-model.trim="taxRates.rate"
              :label="$t('label.futureVatRate')"
              :disabled="isProfile"
              :class="{required: rates.length !== 1}"
              :error-messages="vatRateErrors(v.taxValidator.taxRates, idx)"
              @blur="v.taxValidator.taxRates.$each[idx].rate.$touch()"/>
          </v-col>

          <v-col
            v-if="!isCreate && !isProfile &&
              rates.length > 1"
            cols="1">
            <v-btn
              small text icon
              @click="rates.splice(idx, 1)">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>

          <v-col
            v-if="!isCreate && !isProfile &&
              idx === rates.length - 1"
            cols="1">
            <v-btn
              small
              text
              icon
              @click="rates.push (newTaxObject(rates))">
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import {formatDateShort} from '@/app/core/mixins/DatetimeHelper'
  import DatePickerField, {future} from '@/app/core/components/DatePickerField'

  export default {
    components: {
      DatePickerField
    },

    mixins: [validationMixins],

    props: {
      startDate: {
        type: [Date, Number, String],
        default: undefined
      },
      isProfile: {
        type: Boolean,
        default: false
      },
      isCreate: {
        type: Boolean,
        default: false
      },
      taxInfo: {
        type: Object,
        default: () => ({
          vatRegime: undefined,
          vatId: undefined,
          vatMode: undefined,
          vatInvoiceText: undefined
        })
      },
      taxValidator: {
        type: Object,
        default: undefined
      },
      dateValidator: {
        type: Object,
        default: undefined
      },
      setVatData: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        future,
        v: undefined
      }
    },

    computed: {
      internalSetVatData: {
        get () {
          return this.setVatData
        },
        set (newVal) {
          this.$emit ('update:setVatData', newVal)
        }
      },

      internalStartDate: {
        get  () {
          return formatDateShort (this.startDate, true)
        },
        set (newVal) {
          this.$emit ('update:startDate', newVal ? new Date (newVal).getTime () : null)
        }
      },
      rates: {
        get () {
          return this.taxInfo.taxRates
        },
        set (newVal) {
          this.$emit ('update:taxInfo', {
            ...this.taxInfo,
            taxRates: newVal
          })
          this.$forceUpdate ()
        }
      },

      internalTaxInfo: {
        get () {
          return this.taxInfo
        },
        set (newVal) {
          this.$emit ('update:taxInfo', newVal)
        }
      },

      vatModeOptions () {
        return [
          {
            value: 'required',
            text: this.$t ('vatMode.required'),
            backend: 'Required'
          },
          {
            value: 'not-required',
            text: this.$t ('vatMode.notRequired'),
            backend: 'NotRequired'
          },
          {
            value: 'reverse-charge',
            text: this.$t ('vatMode.reverseCharge'),
            backend: 'ReverseCharge'
          }
        ]
      }

    },

    created () {
      this.v = {
        dateValidator: this.dateValidator,
        taxValidator: this.taxValidator
      }
    },

    methods: {
      vatRateClasses (vatRates, enabled) {
        if (!enabled) {
          return ['col-11', 'col-sm-5']
        } else if (vatRates.length > 1) {
          return ['col-9', 'col-sm-3']
        } else {
          return ['col-10', 'col-sm-4']
        }
      },

      vatRateLabelErrors (vatRatesObject, idx) {
        const errors = []

        if (!vatRatesObject.$each[idx].name.$dirty) {
          return errors
        }

        if (!vatRatesObject.$each[idx].name.required) {
          errors.push (this.$t ('required.vatRateName'))
        }

        return errors
      },

      newTaxObject (obj) {
        const max = obj.reduce ((r, i) => r < i.id ? i.id : r, 0)

        return {id: max + 1, name: '', rate: null}
      },

      vatRateErrors (vatRatesObject, idx) {
        const errors = []

        if (!vatRatesObject.$each[idx].rate.$dirty) {
          return errors
        }

        if (!vatRatesObject.$each[idx].rate.required) {
          errors.push (this.$t ('required.vatRate'))
        }

        if (!vatRatesObject.$each[idx].rate.percentage) {
          errors.push (this.$t ('invalid.vatrate'))
        }

        return errors
      }
    }
  }
</script>
