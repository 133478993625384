var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{key:"hubscheduledbasic"},[_c('v-col',{attrs:{"cols":"12"}},[_c('date-picker-field',{attrs:{"clearable":"","type":"month","allowed-dates":_vm.future,"disabled":_vm.isProfile,"label":_vm.$t('label.futureVatStart'),"error-messages":_vm.validationErrors(
        'dateValidator',
        { required: 'required.vatStartDate' })},on:{"blur":function($event){return _vm.v.dateValidator.$touch()}},model:{value:(_vm.internalStartDate),callback:function ($$v) {_vm.internalStartDate=$$v},expression:"internalStartDate"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_vm._v(" "+_vm._s(_vm.$t('subsection.scheduledVat.setData'))+" "),_c('v-icon',_vm._g({staticClass:"ml-3"},on),[_vm._v(" info ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('subsection.scheduledVat.setDataInfo')))])])]},proxy:true}]),model:{value:(_vm.internalSetVatData),callback:function ($$v) {_vm.internalSetVatData=$$v},expression:"internalSetVatData"}})],1),(_vm.setVatData)?[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"disabled":_vm.isProfile,"label":_vm.$t('label.futureVatRegime')},model:{value:(_vm.internalTaxInfo.vatRegime),callback:function ($$v) {_vm.$set(_vm.internalTaxInfo, "vatRegime", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"internalTaxInfo.vatRegime"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"disabled":_vm.isProfile,"label":_vm.$t('label.futureVatId')},model:{value:(_vm.internalTaxInfo.vatId),callback:function ($$v) {_vm.$set(_vm.internalTaxInfo, "vatId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"internalTaxInfo.vatId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.vatModeOptions,"disabled":_vm.isProfile,"clearable":"","label":_vm.$t('label.futureVatMode'),"error-messages":_vm.validationErrors(
          'taxValidator.vatMode',
          { required: 'required.vatMode' })},on:{"blur":function($event){return _vm.v.taxValidator.vatMode.$touch()}},model:{value:(_vm.internalTaxInfo.vatMode),callback:function ($$v) {_vm.$set(_vm.internalTaxInfo, "vatMode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"internalTaxInfo.vatMode"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"disabled":_vm.isProfile,"rows":"1","label":_vm.$t('label.futureInvoiceText')},model:{value:(_vm.internalTaxInfo.vatInvoiceText),callback:function ($$v) {_vm.$set(_vm.internalTaxInfo, "vatInvoiceText", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"internalTaxInfo.vatInvoiceText"}})],1),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.rates),function(taxRates,idx){return _c('v-row',{key:taxRates.id},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{class:{required: _vm.internalTaxInfo.taxRates.length !== 1},attrs:{"label":_vm.$t('label.futureVatRateName'),"disabled":_vm.isProfile,"error-messages":_vm.vatRateLabelErrors(_vm.v.taxValidator.taxRates, idx)},on:{"blur":function($event){return _vm.v.taxValidator.taxRates.$each[idx].name.$touch()}},model:{value:(taxRates.name),callback:function ($$v) {_vm.$set(taxRates, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"taxRates.name"}})],1),_c('v-col',{class:_vm.vatRateClasses (
            _vm.rates, !_vm.isProfile)},[_c('v-text-field',{class:{required: _vm.rates.length !== 1},attrs:{"label":_vm.$t('label.futureVatRate'),"disabled":_vm.isProfile,"error-messages":_vm.vatRateErrors(_vm.v.taxValidator.taxRates, idx)},on:{"blur":function($event){return _vm.v.taxValidator.taxRates.$each[idx].rate.$touch()}},model:{value:(taxRates.rate),callback:function ($$v) {_vm.$set(taxRates, "rate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"taxRates.rate"}})],1),(!_vm.isCreate && !_vm.isProfile &&
            _vm.rates.length > 1)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"small":"","text":"","icon":""},on:{"click":function($event){return _vm.rates.splice(idx, 1)}}},[_c('v-icon',[_vm._v("delete")])],1)],1):_vm._e(),(!_vm.isCreate && !_vm.isProfile &&
            idx === _vm.rates.length - 1)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"small":"","text":"","icon":""},on:{"click":function($event){_vm.rates.push (_vm.newTaxObject(_vm.rates))}}},[_c('v-icon',[_vm._v("add")])],1)],1):_vm._e()],1)}),1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }