<messages>["./ClientCreateUpdate"]</messages>

<template>
  <v-card>
    <v-card-title>
      <div>
        <div
          v-t="'title.servicesInfo'"
          class="text-h5"/>
        <div
          v-t="'title.servicesInfoSub'"
          class="cgwng-subheading"/>
      </div>
    </v-card-title>

    <v-card-text>
      <v-card v-for="s in showingServices" :key="s" flat class="my-4">
        <div class="text-subtitle-2">
          {{ $t (`service.${s}`) }}:
        </div>

        <div class="pl-4">
          <i18n :path="`serviceInfo.${s}`">
            <template #link>
              <router-link
                target="_blank"
                :to="{name: 'documents'}"
                v-text="$t ('serviceInfo.here')"/>
            </template>
          </i18n>
        </div>
      </v-card>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        v-t="'general.button.close'" text
        @click="$emit('close')"/>
    </v-card-actions>
  </v-card>
</template>

<script>
  const ALL_SERVICES = [
    'receive.email.poll.messages',
    'errp.enabled',
    'errp.notifications.enabled',
    'irtp-c.enabled',
    'wdrp.processing.enabled',
    'wdrp.reporting.enabled',
    'convenience.domain.enabled',
    'incoming.transfer.foa',
    'outgoing.transfer.foa',
    'wap.enabled',
    'invoice.enabled'
  ]

  const DOCUMENTS_LINK = 'https://cpng.corenic.net/#/doc/documents'

  const EXCLUDED_SERVICES = [
    'convenience.domain.enabled'
  ]

  export default {
    props: {
      options: {
        type: Array,
        default: () => ([...ALL_SERVICES])
      }
    },

    computed: {
      showingServices () {
        const ops = [...this.options]

        ops.sort ((a, b) => this.$t (`service.${a}`).localeCompare (this.$t (`service.${b}`)))

        return ops.filter ((s) => !EXCLUDED_SERVICES.includes (s))
      },

      documentsLink () {
        return DOCUMENTS_LINK
      }
    }
  }
</script>
