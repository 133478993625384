<messages>["./ClientCreateUpdate"]</messages>

<template>
  <v-autocomplete
    v-model="selected"
    :label="$t('label.services')"
    :items="sortedOptions"
    multiple>
    <template #selection="data">
      <v-chip
        :key="JSON.stringify (data.item)"
        :close="!data.item.disabled"
        outlined
        color="black"
        class="my-1"
        @update:active="data.parent.selectItem (data.item)">
        <span
          class="chipContent"
          :title="data.item.text">
          {{ data.item.text }}
        </span>
      </v-chip>
    </template>
    <template #append-outer>
      <services-help-dialog
        v-if="sortedOptions && sortedOptions.length > 0"
        v-model="dialog"
        :options="options"/>
    </template>
  </v-autocomplete>
</template>

<script>
  import ServicesHelpDialog from './ServicesHelpDialog'

  export default {
    components: {
      ServicesHelpDialog
    },

    props: {
      options: {
        type: Array,
        required: true
      },

      value: {
        type: Array,
        default: () => ([])
      }
    },

    data () {
      return {
        dialog: false
      }
    },

    computed: {
      selected: {
        set (val) {
          this.$emit ('input', val)
        },
        get () {
          return this.value
        }
      },

      sortedOptions () {
        const ops = [...this.options]
        ops.sort ((a, b) => a.text?.localeCompare (b.text))
        return ops
      }
    }
  }
</script>
