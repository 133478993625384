<messages>["./ClientCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <v-alert
      v-if="!isFormValid"
      id="invalidDataAlert"
      v-t="'general.invalid.form'"
      type="error"
      icon="warning"/>

    <v-card>
      <form
        novalidate
        @submit.prevent="processClientData()">
        <v-card-title primary-title>
          <div>
            <div
              v-t="`client.cu.title.${operationName}`"
              class="text-h5"/>
            <div class="cgwng-subheading">
              {{ $t(`subtitle.${operationName}`, {type: clientTypeString}) }}
            </div>
          </div>
        </v-card-title>

        <v-card-text>
          <v-container
            fluid
            class="pa-0">
            <v-row>
              <v-col
                v-if="!isProfile && mayManageAllEntities"
                cols="12">
                <v-radio-group
                  v-model="client.type"
                  row>
                  <v-container
                    fluid
                    class="pa-0">
                    <v-row>
                      <v-col
                        v-for="typeOption in clientTypeOptions"
                        :key="typeOption.value"
                        cols="12" sm="4" md="2">
                        <v-radio
                          :value="typeOption.value"
                          :disabled="!isCreate"
                          :label="typeOption.label"/>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-radio-group>
              </v-col>

              <v-col
                v-if="clientIsReseller || !mayManageAllEntities"
                cols="12">
                <v-autocomplete
                  v-if="isCreate"
                  v-model="client.parent"
                  class="required"
                  :label="$t('label.parent')"
                  :items="clientOptions"
                  :error-messages="requiredErrors(
                    'client.parent', 'required.parent')"
                  @blur="$v.client.parent.$touch()"/>
                <v-text-field
                  v-else-if="parentName"
                  v-model="parentName"
                  :label="$t('label.parent')"
                  class="required"
                  disabled
                  :error-messages="requiredErrors(
                    'client.parent', 'required.parent')"
                  @blur="$v.client.parent.$touch()"/>
              </v-col>

              <v-col
                v-if="!isCreate || clientIsInternalOrAgent"
                cols="12" sm="6">
                <v-autocomplete
                  v-model="client.registrarId"
                  class="required"
                  :label="$t('label.registrarId')"
                  :items="registrars"
                  :disabled="!isCreate"
                  item-value="id"
                  :error-messages="requiredErrors(
                    'client.registrarId',
                    'required.registrar')"
                  @blur="$v.client.registrarId.$touch()"/>
              </v-col>

              <v-col
                v-if="!isCreate || clientIsMemberPartnerInternalOrAgent"
                cols="12" sm="6">
                <v-text-field
                  v-model="client.id"
                  type="number"
                  :label="$t('label.clientId')"
                  :disabled="!isCreate"
                  class="required"
                  :error-messages="validationErrors(
                    'client.id', { validClientId: 'required.clientId' })"
                  @blur="$v.client.id.$touch()"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="client.name"
                  :label="$t('label.name')"
                  class="required"
                  :error-messages="requiredErrors(
                    'client.name', 'required.name')"
                  @blur="$v.client.name.$touch()"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="client.street"
                  :label="$t('label.street')"
                  class="required"
                  :error-messages="requiredErrors(
                    'client.street', 'required.street')"
                  @blur="$v.client.street.$touch()"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="client.postalCode"
                  spellcheck="false"
                  :label="$t('label.postalCode')"
                  class="required"
                  :error-messages="requiredErrors(
                    'client.postalCode', 'required.postalCode')"
                  @blur="$v.client.postalCode.$touch()"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="client.city"
                  :label="$t('label.city')"
                  class="required"
                  :error-messages="requiredErrors(
                    'client.city', 'required.city')"
                  @blur="$v.client.city.$touch()"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="client.state"
                  :label="$t('label.state')"/>
              </v-col>

              <v-col cols="12" sm="6">
                <country-select
                  v-model="client.countryCode"
                  :label="$t('label.country')"
                  required
                  :error-messages="requiredErrors(
                    'client.countryCode', 'required.countryCode')"
                  @blur="$v.client.countryCode.$touch()"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="client.email"
                  spellcheck="false"
                  :label="$t('label.email')"
                  class="required"
                  :error-messages="validationErrors(
                    'client.email',
                    {
                      required: 'required.email',
                      email: 'general.invalid.email'
                    })"
                  @blur="$v.client.email.$touch()"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="client.pollEmail"
                  spellcheck="false"
                  clearable
                  :label="$t('label.pollNotificationEmail')"
                  :error-messages="validationErrors(
                    'client.pollEmail',
                    {
                      email: 'general.invalid.email'
                    })"
                  @blur="$v.client.pollEmail.$touch()"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="client.phone"
                  :label="$t('label.phone')"
                  :error-messages="validationErrors(
                    'client.phone',
                    {
                      phone: 'general.invalid.phone'
                    })"
                  @blur="$v.client.phone.$touch()"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="client.fax"
                  :label="$t('label.fax')"
                  :error-messages="validationErrors(
                    'client.fax',
                    {
                      phone: 'general.invalid.fax'
                    })"
                  @blur="$v.client.fax.$touch()"/>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="client.webUrl"
                  :label="$t('label.webUrl')"
                  :error-messages="validationErrors(
                    'client.webUrl',
                    {
                      url: 'invalid.url'
                    })"
                  @blur="$v.client.webUrl.$touch()"/>
              </v-col>

              <v-col
                v-if="!isProfile"
                cols="12" sm="6">
                <v-text-field
                  v-model.trim="client.freeText"
                  :label="$t('label.freeText')"
                  :error-messages="validationErrors(
                    'client.freeText',
                    {
                      maxLength: 'invalid.freeText'
                    })"
                  @input="$v.client.freeText.$touch()"/>
              </v-col>

              <v-col
                v-if="isCreate || !isProfile"
                cols="12" sm="6">
                <v-text-field
                  v-model.trim="providerHandle"
                  spellcheck="false"
                  :label="$t('label.providerHandle')"
                  class="required"
                  prefix="{"
                  suffix="}"
                  :disabled="!isCreate"
                  :error-messages="validationErrors(
                    'providerHandle',
                    {
                      required: 'required.providerHandle',
                      providerHandle: 'invalid.providerHandle'
                    })"
                  @blur="$v.providerHandle.$touch()"/>
              </v-col>

              <v-col
                v-if="isCreate || !isProfile"
                cols="12" sm="6">
                <v-text-field
                  v-model.trim="providerChainPrefix"
                  spellcheck="false"
                  class="provider-chain-input required"
                  :label="$t('label.providerChain')"
                  :suffix="'{' + providerHandle + '}'"
                  :error-messages="validationErrors(
                    'providerChainPrefix',
                    {
                      required: 'required.providerChain',
                      providerChain: 'general.invalid.providerChain'
                    })"
                  @blur="$v.providerChainPrefix.$touch()"/>
              </v-col>

              <!-- only visible for members and partners -->
              <v-col
                v-if="clientIsMemberPartnerInternalOrAgent"
                cols="12">
                <v-card flat>
                  <v-card-title
                    primary-title
                    class="pa-0">
                    <div
                      v-t="'section.billingData'"
                      class="text-h5"/>
                  </v-card-title>

                  <v-card-text class="pa-0">
                    <v-container
                      fluid
                      class="pa-0">
                      <v-row>
                        <v-col cols="12">
                          <div
                            v-t="'subsection.account'"
                            class="text-subtitle-1"/>
                        </v-col>

                        <v-col cols="12" sm="4">
                          <currency-select
                            :v="$v.client.currency"
                            :required="!!client.warnThreshold || !!client.minBalance"
                            :disabled="!mayManageAllEntities"/>
                        </v-col>

                        <v-col cols="12" sm="4">
                          <v-text-field
                            v-model="client.warnThreshold"
                            :label="$t('label.warnThreshold')"
                            type="number"
                            :error-messages="validationErrors(
                              'client.warnThreshold',
                              {
                                decimal: 'invalid.threshold'
                              })"
                            @blur="$v.client.warnThreshold.$touch()"/>
                        </v-col>

                        <v-col cols="12" sm="4">
                          <v-text-field
                            v-model="client.minBalance"
                            :label="$t('label.minBalance')"
                            type="number"
                            :disabled="!mayManageAllEntities"
                            :error-messages="validationErrors(
                              'client.minBalance',
                              {
                                decimal: 'invalid.minbalance'
                              })"
                            @blur="$v.client.minBalance.$touch()"/>
                        </v-col>
                      </v-row>

                      <!-- currencies configuration -->

                      <currency-config
                        v-model="client.immediateConversionMappings"
                        :disabled="!mayManageAllEntities"/>
                      <v-row>
                        <v-col cols="12">
                          <div
                            v-t="'subsection.further'"
                            class="text-subtitle-1"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-card flat color="grey lighten-3" class="pa-1">
                            <v-row>
                              <v-col cols="12">
                                <div
                                  v-t="'subsection.coreHub'"
                                  class="text-subtitle-1"/>
                              </v-col>

                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model.trim="client.coreHubInvoiceData.purchaseOrder"
                                  clearable
                                  :error-messages="requiredErrors(
                                    'client.coreHubInvoiceData.purchaseOrder', 'required.purchaseOrder')"
                                  :label="$t('label.purchaseOrder')"/>
                              </v-col>

                              <v-col cols="12" sm="6">
                                <date-picker-field
                                  v-model="hubPurchaseOrderEndDate"
                                  clearable
                                  :error-messages="requiredErrors(
                                    'client.coreHubInvoiceData.purchaseOrderEndDate', 'required.purchaseOrderEndDate')"
                                  :label="$t('label.purchaseOrderEnd')"/>
                              </v-col>

                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model.trim="client.coreHubInvoiceData.paymentOrder"
                                  clearable
                                  :error-messages="requiredErrors(
                                    'client.coreHubInvoiceData.paymentOrder', 'required.paymentOrder')"
                                  :label="$t('label.paymentOrder')"/>
                              </v-col>

                              <v-col cols="12" sm="6">
                                <date-picker-field
                                  v-model="hubPaymentOrderEndDate"
                                  clearable
                                  :error-messages="requiredErrors(
                                    'client.coreHubInvoiceData.paymentOrderEndDate', 'required.paymentOrderEndDate')"
                                  :label="$t('label.paymentOrderEnd')"/>
                              </v-col>
                            </v-row>

                            <!-- the tax data for core hub -->
                            <v-row>
                              <v-col cols="12">
                                <div
                                  class="text-subtitle-1"
                                  v-text="$t (
                                    `subsection.currentVat.coreHub`
                                  )"/>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model.trim="client.coreHubTaxInfo.vatRegime"
                                  :disabled="!isCreate"
                                  :label="$t('label.vatRegime')"/>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model.trim="client.coreHubTaxInfo.vatId"
                                  :disabled="!isCreate"
                                  :label="$t('label.vatId')"/>
                              </v-col>
                              <v-col cols="12">
                                <v-select
                                  v-model.trim="client.coreHubTaxInfo.vatMode"
                                  :disabled="!isCreate"
                                  clearable
                                  :items="vatModeOptions"
                                  :label="$t('label.vatMode')"/>
                              </v-col>
                              <v-col cols="12">
                                <v-textarea
                                  v-model.trim="client.coreHubTaxInfo.vatInvoiceText"
                                  :disabled="!isCreate"
                                  rows="1"
                                  :label="$t('label.vatInvoiceText')"/>
                              </v-col>
                            </v-row>

                            <v-row
                              v-for="(taxRates, idx) in coreHubRates"
                              :key="taxRates.id">
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model.trim="taxRates.name"
                                  :label="$t('label.vatRateName')"
                                  :disabled="!isCreate"
                                  :class="{required: client.coreHubTaxInfo.taxRates.length !== 1}"
                                  :error-messages="vatRateLabelErrors($v.client.coreHubTaxInfo.taxRates, idx)"
                                  @blur="$v.client.coreHubTaxInfo.taxRates.$each[idx].name.$touch()"/>
                              </v-col>

                              <v-col
                                :class="vatRateClasses (client.coreHubTaxInfo.taxRates,
                                                        isCreate && !isProfile)">
                                <v-text-field
                                  v-model.trim="taxRates.rate"
                                  :label="$t('label.vatRate')"
                                  :disabled="!isCreate"
                                  :class="{required: client.coreHubTaxInfo.taxRates.length !== 1}"
                                  :error-messages="vatRateErrors($v.client.coreHubTaxInfo.taxRates, idx)"
                                  @blur="$v.client.coreHubTaxInfo.taxRates.$each[idx].rate.$touch()"/>
                              </v-col>

                              <v-col
                                v-if="isCreate && client.coreHubTaxInfo.taxRates.length > 1"
                                cols="1">
                                <v-btn
                                  top
                                  small text icon
                                  @click="client.coreHubTaxInfo.taxRates.splice(idx, 1)">
                                  <v-icon>delete</v-icon>
                                </v-btn>
                              </v-col>

                              <v-col
                                v-if="isCreate && idx === client.coreHubTaxInfo.taxRates.length - 1"
                                cols="1">
                                <v-btn
                                  top
                                  small text icon
                                  @click="client.coreHubTaxInfo.taxRates.push (newTaxObject(client.coreHubTaxInfo.taxRates))">
                                  <v-icon>add</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>

                            <v-row v-if="!isCreate">
                              <v-col cols="12">
                                <v-switch
                                  v-model="changeHubVatData"
                                  :disabled="isProfile"
                                  :label="$t('subsection.scheduledVat.coreHub')"/>
                              </v-col>
                            </v-row>

                            <!-- future tax data for core hub -->
                            <template
                              v-if="!isCreate && changeHubVatData">
                              <scheduled-vat-data
                                :start-date.sync="coreHubScheduledStartDate"
                                :set-vat-data.sync="setHubVatData"
                                :is-profile="isProfile"
                                :is-create="isCreate"
                                :tax-info.sync="client.coreHubScheduledTaxInfo"
                                :tax-validator="$v.client.coreHubScheduledTaxInfo"
                                :date-validator="$v.client.coreHubScheduledStartDate"/>
                            </template>
                          </v-card>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-card flat color="grey lighten-3" class="pa-1">
                            <v-row>
                              <v-col cols="12">
                                <div
                                  v-t="'subsection.coreAss'"
                                  class="text-subtitle-1"/>
                              </v-col>

                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model.trim="client.coreAssInvoiceData.purchaseOrder"
                                  clearable
                                  :error-messages="requiredErrors(
                                    'client.coreAssInvoiceData.purchaseOrder', 'required.purchaseOrder')"
                                  :label="$t('label.purchaseOrder')"/>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <date-picker-field
                                  v-model="assPurchaseOrderEndDate"
                                  clearable
                                  :error-messages="requiredErrors(
                                    'client.coreAssInvoiceData.purchaseOrderEndDate', 'required.purchaseOrderEndDate')"
                                  :label="$t('label.purchaseOrderEnd')"/>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model.trim="client.coreAssInvoiceData.paymentOrder"
                                  clearable
                                  :error-messages="requiredErrors(
                                    'client.coreAssInvoiceData.paymentOrder', 'required.paymentOrder')"
                                  :label="$t('label.paymentOrder')"/>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <date-picker-field
                                  v-model="assPaymentOrderEndDate"
                                  clearable
                                  :error-messages="requiredErrors(
                                    'client.coreAssInvoiceData.paymentOrderEndDate', 'required.paymentOrderEndDate')"
                                  :label="$t('label.paymentOrderEnd')"/>
                              </v-col>
                            </v-row>

                            <!-- the tax data for core association -->
                            <v-row>
                              <v-col cols="12">
                                <div
                                  class="text-subtitle-1"
                                  v-text="$t (
                                    `subsection.currentVat.coreAss`
                                  )"/>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model.trim="client.coreAssTaxInfo.vatRegime"
                                  :disabled="!isCreate"
                                  :label="$t('label.vatRegime')"/>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model.trim="client.coreAssTaxInfo.vatId"
                                  :disabled="!isCreate"
                                  :label="$t('label.vatId')"/>
                              </v-col>
                              <v-col cols="12">
                                <v-select
                                  v-model.trim="client.coreAssTaxInfo.vatMode"
                                  :disabled="!isCreate"
                                  clearable
                                  :items="vatModeOptions"
                                  :label="$t('label.vatMode')"/>
                              </v-col>
                              <v-col cols="12">
                                <v-textarea
                                  v-model.trim="client.coreAssTaxInfo.vatInvoiceText"
                                  :disabled="!isCreate"
                                  rows="1"
                                  :label="$t('label.vatInvoiceText')"/>
                              </v-col>
                            </v-row>

                            <v-row
                              v-for="(taxRates, idx) in coreAssRates"
                              :key="taxRates.id">
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model.trim="taxRates.name"
                                  :label="$t('label.vatRateName')"
                                  :disabled="!isCreate"
                                  :class="{required: client.coreAssTaxInfo.taxRates.length !== 1}"
                                  :error-messages="vatRateLabelErrors($v.client.coreAssTaxInfo.taxRates, idx)"
                                  @blur="$v.client.coreAssTaxInfo.taxRates.$each[idx].name.$touch()"/>
                              </v-col>

                              <v-col
                                :class="vatRateClasses (client.coreAssTaxInfo.taxRates,
                                                        isCreate && !isProfile)">
                                <v-text-field
                                  v-model.trim="taxRates.rate"
                                  :label="$t('label.vatRate')"
                                  :disabled="!isCreate"
                                  :class="{required: client.coreAssTaxInfo.taxRates.length !== 1}"
                                  :error-messages="vatRateErrors($v.client.coreAssTaxInfo.taxRates, idx)"
                                  @blur="$v.client.coreAssTaxInfo.taxRates.$each[idx].rate.$touch()"/>
                              </v-col>

                              <v-col
                                v-if="isCreate && client.coreAssTaxInfo.taxRates.length > 1"
                                cols="1">
                                <v-btn
                                  top
                                  small text icon
                                  @click="client.coreAssTaxInfo.taxRates.splice(idx, 1)">
                                  <v-icon>delete</v-icon>
                                </v-btn>
                              </v-col>

                              <v-col
                                v-if="isCreate && idx === client.coreAssTaxInfo.taxRates.length - 1"
                                cols="1">
                                <v-btn
                                  top
                                  small text icon
                                  @click="client.coreAssTaxInfo.taxRates.push (newTaxObject(client.coreAssTaxInfo.taxRates))">
                                  <v-icon>add</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>

                            <v-row v-if="!isCreate">
                              <v-col cols="12">
                                <v-switch
                                  v-model="changeAssVatData"
                                  :disabled="isProfile"
                                  :label="$t('subsection.scheduledVat.coreAss')"/>
                              </v-col>
                            </v-row>

                            <!-- future tax data for core hub -->
                            <template
                              v-if="!isCreate && changeAssVatData">
                              <scheduled-vat-data
                                :start-date.sync="coreAssScheduledStartDate"
                                :set-vat-data.sync="setAssVatData"
                                :is-profile="isProfile"
                                :is-create="isCreate"
                                :tax-info.sync="client.coreAssScheduledTaxInfo"
                                :tax-validator="$v.client.coreAssScheduledTaxInfo"
                                :date-validator="$v.client.coreAssScheduledStartDate"/>
                            </template>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  v-model="transferLanguages"
                  :label="$t('label.transferLanguages')"
                  :items="transferLanguageOptions"
                  clearable
                  multiple>
                  <template #selection="data">
                    <v-chip
                      :key="JSON.stringify (data.item)"
                      close
                      outlined
                      color="black"
                      class="my-1"
                      @update:active="data.parent.selectItem (data.item)">
                      <span
                        class="chipContent"
                        :title="data.item">
                        {{ data.item }}
                      </span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <multi-ip-input
                  v-model="client.whoisWhitelist"
                  :label="$t ('label.whoisWhitelist')"
                  :hint="whoisWhitelistHint"/>
              </v-col>
              <v-col
                v-if="!isProfile"
                cols="12">
                <client-group-input
                  v-model="client.groups"
                  :has-parent="client.type === 2 && !!client.parent"
                  :is-create="isCreate"
                  :available-groups="clientGroups"
                  :disabled="hasInvisibleGroups || groupsIncompatible"
                  :error-messages="validationErrors(
                    'client.groups',
                    {
                      required: 'required.groups'
                    })"
                  @blur="$v.client.groups.$touch()"/>
              </v-col>

              <v-col cols="12">
                <select-services v-model="client.enabledServices" :options="serviceOptions"/>
              </v-col>

              <v-col cols="12">
                <v-switch
                  v-model="client.otpRequired"
                  :disabled="isProfile"
                  :label="$t('label.otpRequired')"/>
              </v-col>

              <v-col
                v-if="!isCreate"
                cols="12">
                <v-switch
                  v-model="changeATP"
                  :label="$t('label.changeAtp')"/>
              </v-col>
              <v-col
                v-if="isCreate || changeATP"
                cols="12">
                <!--
                  this field is a workaround for FF to prevent autocompletion
                  of the field, previous to the password (GWNG-416)
                -->
                <input id="email" style="display:none" type="text" name="ffWorkaroundEmail">
                <input id="password" style="display:none" type="password" name="ffWorkaroundPassoword">
                <v-text-field
                  v-model.trim="client.txnAtp"
                  autocomplete="new-password"
                  spellcheck="false"
                  :label="$t('label.atp')"
                  :append-icon="isATPHidden ? 'visibility' : 'visibility_off'"
                  :type="isATPHidden ? 'password' : 'text'"
                  @click:append="isATPHidden = !isATPHidden"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <floating-button
            color="primary"
            :l-offset="2"
            :b-offset="2">
            {{ $t(`general.button.${isCreate ? 'create' : 'update'}`) }}
          </floating-button>
          <v-spacer/>
          <v-btn
            v-t="'general.button.cancel'"
            @click="onCancel"/>
        </v-card-actions>
      </form>
    </v-card>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
  import goTo from 'vuetify/lib/services/goto'
  import {formatDateShort} from '@/app/core/mixins/DatetimeHelper'

  import CountrySelect from '@/app/core/components/CountrySelect'
  import CurrencyConfig from '@/app/pages/Client/components/CurrencyConfig'
  import CurrencySelect from '@/app/core/components/CurrencySelect'
  import DatePickerField, {future} from '@/app/core/components/DatePickerField'
  import FloatingButton from '@/app/core/components/FloatingButton'
  import MultiIpInput from '@/app/core/components/Inputs/MultiIpInput'
  import IpAddressesValidation
    from '@/app/core/components/Inputs/IpAddressesValidation'

  import ClientGroupInput from './AuthorizationGroup/ClientGroupInput'
  import SelectServices from './SelectServices'
  import ScheduledVatData from './ScheduledVatData'

  import _cloneDeep from 'lodash/cloneDeep'
  import _isEqual from 'lodash/isEqual'
  import _isObject from 'lodash/isObject'
  import _transform from 'lodash/transform'

  import {
    and,
    integer,
    maxLength,
    minValue,
    required,
    requiredIf,
    url
  } from 'vuelidate/lib/validators'

  import {
    decimal,
    phone,
    percentage,
    providerChain,
    providerHandle,
    validateIf,
    email
  } from '@/app/validators/index'

  import ContentHeightReporter from '@/app/core/mixins/ContentHeightReporter'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  const MEMBER = 1
  const RESELLER = 2
  const PARTNER = 3
  const INTERNAL = 4
  const AGENT = 5

  const INITIAL_CLIENT = {
    id: '',
    type: -1,
    parent: '',
    registrarId: '',
    name: '',
    street: '',
    postalCode: '',
    city: '',
    state: '',
    countryCode: '',
    email: '',
    pollEmail: '',
    phone: '',
    fax: '',
    webUrl: '',
    freeText: '',
    providerHandle: '',
    defaultProviderChain: '',
    currency: '',
    warnThreshold: '',
    minBalance: '',
    immediateConversionMappings: [],
    coreHubTaxInfo: {
      vatId: '',
      taxRates: [{id: 0, label: '', rate: ''}],
      vatMode: '',
      vatInvoiceText: '',
      vatRegime: ''
    },
    coreAssTaxInfo: {
      vatId: '',
      taxRates: [{id: 0, label: '', rate: ''}],
      vatMode: '',
      vatInvoiceText: '',
      vatRegime: ''
    },
    coreHubScheduledStartDate: null,
    coreHubScheduledTaxInfo: {
      vatId: '',
      taxRates: [{id: 0, label: '', rate: ''}],
      vatMode: '',
      vatInvoiceText: '',
      vatRegime: ''
    },
    coreAssScheduledStartDate: null,
    coreAssScheduledTaxInfo: {
      vatId: '',
      taxRates: [{id: 0, label: '', rate: ''}],
      vatMode: '',
      vatInvoiceText: '',
      vatRegime: ''
    },
    // purchaseOrder: '',
    // purchaseOrderEnd: null,
    // paymentOrder: '',
    // paymentOrderEnd: null,
    transferLanguages: [],
    whoisWhitelist: [],
    groups: [],
    enabledServices: [1, 99],
    txnAtp: '',
    otpRequired: false,
    coreAssInvoiceData: {
      purchaseOrder: '',
      purchaseOrderEndDate: null,
      paymentOrder: '',
      paymentOrderEndDate: null
    },
    coreHubInvoiceData: {
      purchaseOrder: '',
      purchaseOrderEndDate: null,
      paymentOrder: '',
      paymentOrderEndDate: null
    }
  }

  export default {
    name: 'ClientCreateUpdate',

    components: {
      CountrySelect,
      CurrencyConfig,
      CurrencySelect,
      DatePickerField,
      FloatingButton,
      MultiIpInput,
      ClientGroupInput,
      SelectServices,
      ScheduledVatData
    },

    mixins: [ContentHeightReporter, validationMixins],

    props: {
      clientData: {
        type: Object,
        default: undefined
      },
      isCreate: Boolean,
      isProfile: Boolean
    },

    data: () => ({
      changeHubVatData: false,
      changeAssVatData: false,
      setHubVatData: true,
      setAssVatData: true,
      changeATP: false,
      isATPHidden: true,
      parentName: '',
      clients: [],
      registrars: [],
      clientGroups: [],
      services: [],
      forcedServices: [],
      maxVatRateId: 0,
      clientTypes: ['member', 'reseller', 'partner', 'internal', 'agent'],
      providerHandle: '',
      providerChainPrefix: '',
      defaultClient: null,
      future,
      currencyLabel: undefined,
      client: {},
      groupsIncompatible: false
    }),

    validations: {
      providerHandle: {
        required: requiredIf (function () {
          return this.isCreate
        }),
        providerHandle: validateIf (function () {
          return this.isCreate
        }, providerHandle)
      },
      providerChainPrefix: {required, providerChain},
      client: {
        coreAssInvoiceData: {
          purchaseOrderEndDate: {
            required: requiredIf (
              function () {
                return (this.client.coreAssInvoiceData.purchaseOrder &&
                  this.client.coreAssInvoiceData.purchaseOrder !== '')
              }
            )
          },
          purchaseOrder: {
            required: requiredIf (
              function () {
                return (this.client.coreAssInvoiceData.purchaseOrderEndDate)
              }
            )
          },

          paymentOrderEndDate: {
            required: requiredIf (
              function () {
                return (this.client.coreAssInvoiceData.paymentOrder &&
                  this.client.coreAssInvoiceData.paymentOrder !== '')
              }
            )
          },
          paymentOrder: {
            required: requiredIf (
              function () {
                return (this.client.coreAssInvoiceData.paymentOrderEndDate)
              }
            )
          }
        },

        coreHubInvoiceData: {
          purchaseOrderEndDate: {
            required: requiredIf (
              function () {
                return (this.client.coreHubInvoiceData.purchaseOrder &&
                  this.client.coreHubInvoiceData.purchaseOrder !== '')
              }
            )
          },
          purchaseOrder: {
            required: requiredIf (
              function () {
                return (this.client.coreHubInvoiceData.purchaseOrderEndDate)
              }
            )
          },

          paymentOrderEndDate: {
            required: requiredIf (
              function () {
                return (this.client.coreHubInvoiceData.paymentOrder &&
                  this.client.coreHubInvoiceData.paymentOrder !== '')
              }
            )
          },
          paymentOrder: {
            required: requiredIf (
              function () {
                return (this.client.coreHubInvoiceData.paymentOrderEndDate)
              }
            )
          }
        },

        coreHubTaxInfo: {
          taxRates: {
            $each: {
              name: {
                required: requiredIf (
                  function (vatRateModel) {
                    return (this.client.coreHubTaxInfo.taxRates.length > 1 ||
                      vatRateModel.rate)
                  })
              },
              rate: {
                required: requiredIf (
                  function (vatRateModel) {
                    return (this.client.coreHubTaxInfo.taxRates.length > 1 ||
                      vatRateModel.name)
                  }),
                percentage: validateIf (
                  function (vatRateModel) {
                    return (this.client.coreHubTaxInfo.taxRates.length > 1 ||
                      vatRateModel.name)
                  }, percentage)
              }
            }
          }
        },

        coreAssTaxInfo: {
          taxRates: {
            $each: {
              name: {
                required: requiredIf (
                  function (vatRateModel) {
                    return (this.client.coreAssTaxInfo.taxRates.length > 1 ||
                      vatRateModel.rate)
                  })
              },
              rate: {
                required: requiredIf (
                  function (vatRateModel) {
                    return (this.client.coreAssTaxInfo.taxRates.length > 1 ||
                      vatRateModel.name)
                  }),
                percentage: validateIf (
                  function (vatRateModel) {
                    return (this.client.coreAssTaxInfo.taxRates.length > 1 ||
                      vatRateModel.name)
                  }, percentage)
              }
            }
          }
        },

        coreHubScheduledStartDate: {
          required: requiredIf (function () {
            return this.changeHubVatData
          })
        },

        coreHubScheduledTaxInfo: {
          vatMode: {
            required: requiredIf (function () {
              return this.changeHubVatData && (
                this.setHubVatData ||
                this.client.coreHubScheduledTaxInfo.taxRates[0]?.name ||
                this.client.coreHubScheduledTaxInfo.taxRates[0]?.rate ||
                this.client.coreHubScheduledTaxInfo.vatId ||
                this.client.coreHubScheduledTaxInfo.vatInvoiceText ||
                this.client.coreHubScheduledTaxInfo.vatRegime
              )
            })
          },
          taxRates: {
            $each: {
              name: {
                required: requiredIf (
                  function (vatRateModel) {
                    return this.changeHubVatData && (
                      this.client.coreHubScheduledTaxInfo.taxRates.length > 1 ||
                      vatRateModel.rate)
                  })
              },
              rate: {
                required: requiredIf (
                  function (vatRateModel) {
                    return this.changeHubVatData && (
                      this.client.coreHubScheduledTaxInfo.taxRates.length > 1 ||
                      vatRateModel.name)
                  }),
                percentage: validateIf (
                  function (vatRateModel) {
                    return this.changeHubVatData && (
                      this.client.coreHubScheduledTaxInfo.taxRates.length > 1 ||
                      vatRateModel.name)
                  }, percentage)
              }
            }
          }
        },
        coreAssScheduledStartDate: {
          required: requiredIf (function () {
            return this.changeAssVatData
          })
        },

        coreAssScheduledTaxInfo: {
          vatMode: {
            required: requiredIf (function () {
              return this.changeAssVatData && (
                this.setAssVatData ||
                this.client.coreAssScheduledTaxInfo.taxRates[0]?.name ||
                this.client.coreAssScheduledTaxInfo.taxRates[0]?.rate ||
                this.client.coreAssScheduledTaxInfo.vatId ||
                this.client.coreAssScheduledTaxInfo.vatInvoiceText ||
                this.client.coreAssScheduledTaxInfo.vatRegime
              )
            })
          },
          taxRates: {
            $each: {
              name: {
                required: requiredIf (
                  function (vatRateModel) {
                    return this.changeAssVatData && (
                      this.client.coreAssScheduledTaxInfo.taxRates.length > 1 ||
                      vatRateModel.rate)
                  })
              },
              rate: {
                required: requiredIf (
                  function (vatRateModel) {
                    return this.changeAssVatData && (
                      this.client.coreAssScheduledTaxInfo.taxRates.length > 1 ||
                      vatRateModel.name)
                  }),
                percentage: validateIf (
                  function (vatRateModel) {
                    return this.changeAssVatData && (
                      this.client.coreAssScheduledTaxInfo.taxRates.length > 1 ||
                      vatRateModel.name)
                  }, percentage)
              }
            }
          }
        },
        parent: {
          required: requiredIf (function () {
            return this.clientIsReseller
          })
        },
        registrarId: {
          required: requiredIf (function () {
            return this.clientIsInternalOrAgent
          })
        },
        id: {
          validClientId: validateIf (function () {
            return this.clientIsMemberPartnerInternalOrAgent
          }, and (required, integer, minValue (1)))
        },
        name: {required},
        street: {required},
        postalCode: {required},
        city: {required},
        countryCode: {required},
        email: {required, email},
        pollEmail: {email},
        webUrl: {url},
        freeText: {maxLength: maxLength (255)},
        groups: {required},
        phone: {phone},
        fax: {phone},
        whoisWhitelist: IpAddressesValidation,
        currency: {
          required: requiredIf (function (clientModel) {
            return clientModel.warnThreshold || clientModel.minBalance
          })
        },
        minBalance: {
          decimal: validateIf (function () {
            return this.clientIsMemberPartnerInternalOrAgent
          }, decimal)
        },
        warnThreshold: {
          decimal: validateIf (function () {
            return this.clientIsMemberPartnerInternalOrAgent
          }, decimal)
        }
      }
    },

    computed: {
      ...mapState ('auth', [
        'userLoginData'
      ]),
      ...mapGetters ('auth', [
        'clientName',
        'clientId',
        'mayManageAllEntities'
      ]),

      coreHubRates: {
        get () {
          return this.client.coreHubTaxInfo?.taxRates
        },
        set (newVal) {
          this.client.coreHubTaxInfo = this.client.coreHubTaxInfo
            ? this.$set (this.client.coreHubTaxInfo.taxRates, newVal)
            : this.$set (this.client.coreHubTaxInfo, {taxRates: newVal})
        }
      },

      coreAssRates: {
        get () {
          return this.client.coreAssTaxInfo?.taxRates
        },
        set (newVal) {
          this.client.coreAssTaxInfo = this.client.coreAssTaxInfo
            ? this.$set (this.client.coreAssTaxInfo.taxRates, newVal)
            : this.$set (this.client.coreAssTaxInfo, {taxRates: newVal})
        }
      },

      coreHubScheduledRates: {
        get () {
          return this.client.coreHubScheduledTaxInfo?.taxRates
        },
        set (newVal) {
          this.client = {
            ...this.client,
            coreHubScheduledTaxInfo: {
              ...this.client.coreHubScheduledTaxInfo,
              taxRates: newVal
            }
          }
          this.$forceUpdate ()
        }
      },

      coreAssScheduledRates: {
        get () {
          return this.client.coreAssScheduledTaxInfo?.taxRates
        },
        set (newVal) {
          this.client = {
            ...this.client,
            coreAssScheduledTaxInfo: {
              ...this.client.coreAssScheduledTaxInfo,
              taxRates: newVal
            }
          }
          this.$forceUpdate ()
        }
      },

      operationName () {
        return this.isCreate
          ? 'create'
          : (this.isProfile ? 'profile' : 'update')
      },

      clientTypeString () {
        return (this.clientTypeOptions.filter (item =>
          item.value === this.client.type).map (item => item.label))[0] ||
          this.$t ('label.clientType.unspecified')
      },

      clientTypePrefix () {
        const idx = this.client.type - 1

        return this.clientTypes[idx].slice (0, 3).toUpperCase ()
      },

      clientTypeOptions () {
        return this.clientTypes.map (
          (type, index) => ({
            value: index + 1,
            label: this.$t (`label.clientType.${type}`)
          })
        )
      },

      clientOptions () {
        return this.clients.map (({id, name}) =>
          (
            {
              text: `${name} (${id})`,
              value: id
            }
          ))
      },

      whoisWhitelistHint () {
        // the hint will be shown only if no `ManageAllEntities` privilege
        // is set
        return (!this.mayManageAllEntities &&
          this.$t ('label.whoisWhitelistHint')) || undefined
      },

      transferLanguageOptions () {
        return ['nl', 'en', 'fr', 'de'].map (this.langToTransferLanguageOption)
      },

      transferLanguages: {
        get () {
          return this.client.transferLanguages.map (
            this.langToTransferLanguageOption)
        },

        set (newValue) {
          // if the value was changed (without this check get the endless loop)
          if (this.client.transferLanguages.length !== newValue.length ||
            this.client.transferLanguages.some (
              (v, i) => !newValue[i].includes (`(${v})`))) {
            // let allowed languages only and convert to values
            this.client.transferLanguages =
              newValue.filter (it =>
                this.transferLanguageOptions.includes (it)).map (text =>
                text.replace (/^.*?\((..)\).*/, '$1'))
          }
        }
      },

      serviceOptions () {
        return this.services.map (item => ({
          text: this.$t (`service.${item}`),
          value: item,
          disabled: this.forcedServices.includes (item)
        })).sort ((u1, u2) => u1.text.localeCompare (u2.text))
      },

      vatModeOptions () {
        return [
          {
            value: 'required',
            text: this.$t ('vatMode.required'),
            backend: 'Required'
          },
          {
            value: 'not-required',
            text: this.$t ('vatMode.notRequired'),
            backend: 'NotRequired'
          },
          {
            value: 'reverse-charge',
            text: this.$t ('vatMode.reverseCharge'),
            backend: 'ReverseCharge'
          }
        ]
      },

      /**
       * recursively go through Vuelidate object (this.$v) and check if some
       * of its properties has {@code $error} field set to {@code true};
       * if so, the whole form is treated as invalid.
       */
      isFormValid () {
        function isLevelValid (level) {
          let valid = true
          if (!level.$error) {
            for (const prop in level) {
              if (level.hasOwnProperty (prop) &&
                typeof level[prop] === 'object' && level[prop]) {
                valid = isLevelValid (level[prop])
                if (!valid) {
                  break
                }
              }
            }
          } else {
            valid = false
          }

          return valid
        }

        return isLevelValid (this.$v)
      },

      clientIsMemberPartnerInternalOrAgent () {
        return [MEMBER, PARTNER, INTERNAL, AGENT].includes (this.client.type)
      },

      clientIsInternalOrAgent () {
        return [INTERNAL, AGENT].includes (this.client.type)
      },

      clientIsReseller () {
        return this.client.type === RESELLER
      },

      hasInvisibleGroups () {
        return this.clientGroups.some (g => !g.visibleForClient)
      },

      coreHubScheduledStartDate: {
        get  () {
          return formatDateShort (this.client.coreHubScheduledStartDate, true)
        },
        set (newVal) {
          this.client = {
            ...this.client,
            coreHubScheduledStartDate: newVal ? new Date (newVal).getTime () : null
          }
        }
      },

      coreAssScheduledStartDate: {
        get  () {
          return formatDateShort (this.client.coreAssScheduledStartDate, true)
        },
        set (newVal) {
          this.client = {
            ...this.client,
            coreAssScheduledStartDate: newVal ? new Date (newVal).getTime () : null
          }
        }
      },

      assPaymentOrderEndDate: {
        get () {
          return formatDateShort (this.client.coreAssInvoiceData.paymentOrderEndDate, true)
        },
        set (newVal) {
          const time = new Date (newVal).getTime ()
          this.client.coreAssInvoiceData.paymentOrderEndDate = Number.isNaN (time) ? null : time
        }
      },
      hubPaymentOrderEndDate: {
        get () {
          return formatDateShort (this.client.coreHubInvoiceData.paymentOrderEndDate, true)
        },
        set (newVal) {
          const time = new Date (newVal).getTime ()
          this.client.coreHubInvoiceData.paymentOrderEndDate = Number.isNaN (time) ? null : time
        }
      },
      assPurchaseOrderEndDate: {
        get () {
          return formatDateShort (this.client.coreAssInvoiceData.purchaseOrderEndDate, true)
        },
        set (newVal) {
          const time = new Date (newVal).getTime ()
          this.client.coreAssInvoiceData.purchaseOrderEndDate = Number.isNaN (time) ? null : time
        }
      },
      hubPurchaseOrderEndDate: {
        get () {
          return formatDateShort (this.client.coreHubInvoiceData.purchaseOrderEndDate, true)
        },
        set (newVal) {
          const time = new Date (newVal).getTime ()
          this.client.coreHubInvoiceData.purchaseOrderEndDate = Number.isNaN (time) ? null : time
        }
      }
    },

    watch: {
      clientData (newData) {
        this.populateClientObject (newData)
        this.defaultClient = _cloneDeep (this.client)
        this.$emit ('unsavedChanges', false)
      },

      clientGroups (newValue) {
        // if (this.isCreate) {
        //   if (newValue.length === 1) {
        //     this.client.groups = [newValue[0].id]
        //   } else {
        //     this.client.groups = []
        //     this.$v.client.groups.$reset ()
        //   }
        // }
      },

      client: {
        handler (data) {
          const deepDiffMapper = (object, base) => {
            function changes (object, base) {
              return _transform (object, function (result, value, key) {
                if (!_isEqual (value, base[key]) && key !== 'enabledServices') {
                  result[key] = (_isObject (value) && _isObject (base[key]))
                    ? changes (value, base[key])
                    : value
                }
              })
            }

            return changes (object, base)
          }

          const diff = deepDiffMapper (
            _cloneDeep (data), _cloneDeep (this.defaultClient))

          if (this.defaultClient &&
            !_isEqual (data, this.defaultClient) &&
            !_isEqual ({}, diff)) {
            this.$emit ('unsavedChanges', true)
          } else {
            this.$emit ('unsavedChanges', false)
          }
        },
        deep: true
      },

      'client.parent' (newValue) {
        this.listServices (newValue, this.client.groups)

        if (!this.isProfile) {
          this.listClientGroups (newValue)
        }

        if (newValue) {
          if (this.isCreate) {
            this.loadProviderChainForReseller (newValue)
          } else {
            (async () => {
              this.parentName = await this.getClientNameExt (newValue)
            }) ()
          }
        }
      },

      'client.registrarId' (newValue) {
        if (this.isCreate) {
          this.loadProviderChainForMemberOrPartner (newValue)
        }
      },

      'client.groups': {
        immediate: true,
        handler (newValue) {
          if (newValue) {
            this.listServices (this.client.parent, newValue)
          }
        }
      },

      'client.type' (newValue) {
        this.providerChainPrefix = ''

        if (this.isCreate) {
          if (this.client) {
            switch (newValue) {
              case MEMBER: // intentional fall-through
              case PARTNER:
              case INTERNAL:
              case AGENT:
                if (this.client.registrarId) {
                  this.loadProviderChainForMemberOrPartner (
                    this.client.registrarId)
                }

                this.listClientGroups (null)
                break

              case RESELLER:
                if (this.client.parent) {
                  this.loadProviderChainForReseller (this.client.parent)
                  this.listClientGroups (this.client.parent)
                }
            }
          }

          this.getNewProviderHandle ()
        }
      },

      'client.defaultProviderChain' (newValue) {
        this.providerChainPrefix =
          newValue.slice (0, newValue.lastIndexOf ('{'))
      },

      'client.providerHandle' (newValue) {
        this.providerHandle = newValue.slice (1, newValue.length - 1)
      }
    },

    created () {
      // this is needed to populate statically specified client options,
      // e.g. on client creation (see ClientCreate.vue)
      this.populateClientObject (this.clientData)
      this.listRegistrars ()

      if (this.isCreate) {
        this.getNewProviderHandle ()
      }

      if (!this.isProfile) {
        if (this.clientIsReseller || !this.mayManageAllEntities) {
          if (this.isCreate) {
            this.listClients ()
          }
        }
      }
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        getClientNameExt: 'cache/getClientNameExt',
        fetchData: 'request/fetchData'
      }),

      /**
       * enforce validation of {@code client.scheduledDataStart}
       */
      validateScheduledDataStart () {
        this.$v.client.scheduledDataStart.$touch ()
      },

      langToTransferLanguageOption (lang) {
        return this.$t (`label.language.${lang}`)
      },

      // retrieve the list of clients
      listClients () {
        return this.fetchData ({
          op: 'client/list/all',
          cb: data => {
            this.clients = data.list
            const id = this.clientId.effectiveClientId || this.clientId.clientId

            const name =
              this.clientName.effectiveClientName || this.clientName.clientName

            if (id && name) {
              this.clients.unshift ({id, name})
            }
          }
        })
      },

      // retrieve the list of registrars
      listRegistrars () {
        return this.fetchData ({
          op: 'registrar/list',
          cb: data => {
            const regObjects = data.registrars
            // add 'text' property to be used as label
            this.registrars = regObjects.map (
              elem => ({
                ...elem,
                text: elem.displayName + (elem.ianaId ? ` (${elem.ianaId})` : '')
              })
            )
          }
        })
      },

      // retrieve the list of client groups
      listClientGroups (clientId) {
        return this.fetchData ({
          op: 'client/data/groups',
          params: {
            id: clientId || null,
            groups: this.isCreate ? null : this.client.groups
          },
          cb: data => {
            this.groupsIncompatible = false
            const returnedGroups = data.list

            // if it is edit page and returned groups includes invisible for
            // client the only apply the groups the user already has
            if (!this.isCreate) {
              if (returnedGroups.find ((g) => !g.visibleForClient)) {
                this.groupsIncompatible = true
                this.clientGroups = [
                  ...returnedGroups.filter ((g) =>
                    this.client.groups.includes (g.id))
                ]
              } else {
                this.clientGroups = [...returnedGroups]
              }
            } else {
              this.clientGroups = [...returnedGroups]
              if (this.client.parent && this.clientIsReseller) {
                this.client.groups = this.clientGroups.map ((g) => g.id)
              } else {
                this.client.groups = []
              }
            }
          }
        })
      },

      // retrieve the list of services
      listServices (clientId, groupIds) {
        if (!this.isCreate && (!groupIds || groupIds.length === 0)) {
          return
        }

        return this.fetchData ({
          op: 'client/data/services',
          params: {
            clientId: this.client.type === RESELLER ? clientId : null,
            groupIds: groupIds
          },
          cb: data => {
            this.services = data.availableServices
            this.forcedServices = data.forcedServices

            const withDuplicates = [
              ...this.client.enabledServices.filter (
                s => this.services.includes (s) &&
                  !this.forcedServices.includes (s)),
              ...this.forcedServices]

            const deduplicated = []

            withDuplicates.forEach ((service) => {
              if (!deduplicated.includes (service)) {
                deduplicated.push (service)
              }
            })

            this.client.enabledServices = deduplicated
          }
        })
      },

      getNewProviderHandle () {
        return this.fetchData ({
          op: 'client/mgmt/providerHandle',
          params: {
            type: this.clientTypePrefix
          },
          cb: data => {
            this.client.providerHandle = data.providerHandle
          }
        })
      },

      loadProviderChainForReseller (parentId) {
        return this.fetchData ({
          op: 'client/data/providerChain',
          params: {
            id: parentId
          },
          cb: data => {
            this.providerChainPrefix = data.providerChain
          }
        })
      },

      loadProviderChainForMemberOrPartner (registrarId) {
        const registrar = this.registrars.find (r => r.id === registrarId)
        this.providerChainPrefix = `{REG-${registrar?.name}}`
      },

      // populate this component's client with the values from the dataObject
      populateClientObject (dataObject) {
        this.client = _cloneDeep (INITIAL_CLIENT)
        Object.assign (this.client, dataObject)

        if (this.isCreate) {
          this.client.id = null
        }

        const {
          coreHubScheduledTaxInfo, coreAssScheduledTaxInfo, coreHubTaxInfo, coreAssTaxInfo
        } = dataObject

        if (coreHubTaxInfo) {
          this.client.coreHubTaxInfo.vatMode = this.vatModeOptions
            .find ((v) => v.backend === this.client.coreHubTaxInfo.vatMode)?.value

          if (coreHubTaxInfo.taxRates) {
            this.client.coreHubTaxInfo.taxRates = coreHubTaxInfo.taxRates.map ((object, idx) => ({
              id: idx,
              ...object
            }))
          }
        }

        if (coreAssTaxInfo) {
          this.client.coreAssTaxInfo.vatMode = this.vatModeOptions
            .find ((v) => v.backend === this.client.coreAssTaxInfo.vatMode)?.value

          if (coreAssTaxInfo.taxRates) {
            this.client.coreAssTaxInfo.taxRates = coreAssTaxInfo.taxRates.map ((object, idx) => ({
              id: idx,
              ...object
            }))
          }
        }

        if (coreHubScheduledTaxInfo) {
          this.client.coreHubScheduledTaxInfo.vatMode = this.vatModeOptions
            .find ((v) => v.backend === this.client.coreHubScheduledTaxInfo.vatMode)?.value
        }

        if (!coreHubScheduledTaxInfo) {
          this.client.coreHubScheduledTaxInfo = {
            vatId: '',
            taxRates: [{id: 1, name: '', rate: ''}],
            vatMode: null,
            invoiceText: '',
            vatRegime: ''
          }
        } else if (!coreHubScheduledTaxInfo.taxRates || coreHubScheduledTaxInfo.taxRates.length === 0) {
          this.client.coreHubScheduledTaxInfo = {
            ...coreHubScheduledTaxInfo,
            taxRates: [{id: 1, name: '', rate: ''}]
          }
        }

        if (this.client.coreHubScheduledStartDate) {
          this.changeHubVatData = true
          this.setHubVatData = !!this.client.coreHubScheduledTaxInfo.vatMode
        }

        if (this.client.coreAssScheduledStartDate) {
          this.changeAssVatData = true
          this.setAssVatData = !!this.client.coreAssScheduledTaxInfo.vatMode
        }

        if (coreAssScheduledTaxInfo) {
          this.client.coreAssScheduledTaxInfo.vatMode = this.vatModeOptions
            .find ((v) => v.backend === this.client.coreAssScheduledTaxInfo.vatMode)?.value
        }

        if (!coreAssScheduledTaxInfo) {
          this.client.coreAssScheduledTaxInfo = {
            vatId: '',
            taxRates: [{id: 1, name: '', rate: ''}],
            vatMode: null,
            invoiceText: '',
            vatRegime: ''
          }
        } else if (!coreAssScheduledTaxInfo.taxRates || coreAssScheduledTaxInfo.taxRates.length === 0) {
          this.client.coreAssScheduledTaxInfo = {
            ...coreAssScheduledTaxInfo,
            taxRates: [{id: 1, name: '', rate: ''}]
          }
        }

        this.defaultClient = _cloneDeep (this.client)
        this.$v.$reset ()
      },

      // store or update the client
      processClientData () {
        // TODO, FIXME: this is a workaround for https://github.com/vuetifyjs/vuetify/issues/5203
        // (if submitted directly from combobox, then the combobox value is not updated yet, so wait...)
        // This workaround can be reverted as soon as the issue is resolved and the Vuetify library is updated
        setTimeout (() => {
          if (!this.$v.$invalid) {
            const params = {
              clientId: this.client.id,
              clientType: this.client.type,
              clientName: this.client.name,
              street: this.client.street,
              postalCode: this.client.postalCode,
              city: this.client.city,
              state: this.client.state,
              countryCode: this.client.countryCode,
              email: this.client.email,
              pollEmail: this.client.pollEmail,
              phone: this.client.phone,
              fax: this.client.fax,
              webUrl: this.client.webUrl,
              freeText: this.client.freeText,
              transferLanguages: this.client.transferLanguages,
              whoisWhitelist: this.client.whoisWhitelist,
              groups: this.client.groups,
              enabledServices: this.client.enabledServices,
              otpRequired: this.client.otpRequired
            }

            params.updateGroups = this.isCreate || this.isProfile ||
              !this.hasInvisibleGroups

            const clientProviderHandle = '{' + this.providerHandle + '}'

            if (!this.isProfile) {
              params.defaultProviderChain =
                this.providerChainPrefix + clientProviderHandle
            }

            if (this.isCreate) {
              params.providerHandle = clientProviderHandle

              params.defaultProviderChain =
                this.providerChainPrefix + clientProviderHandle
            }

            if (this.isCreate && this.clientIsReseller) {
              params.clientId = null
            }

            if (this.isCreate || this.changeATP) {
              params.txnAtp = this.client.txnAtp
            }

            if (this.clientIsReseller) {
              params.parentClientId = this.client.parent
            }

            if (this.clientIsMemberPartnerInternalOrAgent) {
              params.warnThreshold = this.client.warnThreshold
              params.minBalance = this.client.minBalance

              if (!this.isProfile) {
                params.currency = this.client.currency

                params.immediateConversionMappings =
                  this.client.immediateConversionMappings
              }

              if (this.isCreate) {
                params.registrarId = this.clientIsInternalOrAgent ? this.client.registrarId : null
                params.vatId = this.client.vatId

                params.coreAssTaxInfo = {
                  ...this.client.coreAssTaxInfo,
                  taxRates: this.client.coreAssTaxInfo?.taxRates.map (({name, rate}) => ({name, rate}))
                    .filter (({name, rate}) => name || rate)
                }

                params.coreHubTaxInfo = {
                  ...this.client.coreHubTaxInfo,
                  taxRates: this.client.coreHubTaxInfo?.taxRates.map (({name, rate}) => ({name, rate}))
                    .filter (({name, rate}) => name || rate)
                }
              }

              if (this.changeHubVatData) {
                params.coreHubScheduledStartDate = this.client.coreHubScheduledStartDate
              }

              if (!this.isCreate && this.changeHubVatData &&
                this.client.coreHubScheduledStartDate && this.setHubVatData) {
                params.coreHubScheduledTaxInfo = {
                  ...this.client.coreHubScheduledTaxInfo,
                  taxRates: this.client.coreHubScheduledTaxInfo.taxRates?.map (({name, rate}) => ({name, rate}))
                    .filter (({name, rate}) => name || rate)
                }
              }

              if (this.changeAssVatData) {
                params.coreAssScheduledStartDate = this.client.coreAssScheduledStartDate
              }

              if (!this.isCreate && this.changeAssVatData &&
                this.client.coreAssScheduledStartDate && this.setAssVatData) {
                params.coreAssScheduledTaxInfo = {
                  ...this.client.coreAssScheduledTaxInfo,
                  taxRates: this.client.coreAssScheduledTaxInfo?.taxRates.map (({name, rate}) => ({name, rate}))
                    .filter (({name, rate}) => name || rate)
                }
              }

              params.coreAssInvoiceData = this.client.coreAssInvoiceData
              params.coreHubInvoiceData = this.client.coreHubInvoiceData

              // params.purchaseOrder = this.client.purchaseOrder
              // params.purchaseOrderEndDate = this.client.purchaseOrderEnd
              // params.paymentOrder = this.client.paymentOrder
              // params.paymentOrderEndDate = this.client.paymentOrderEnd
            }

            this.fetchData ({
              op: this.isCreate
                ? 'client/create'
                : (this.isProfile ? 'client/profile/update' : 'client/update'),
              params,
              cb: data => {
                this.displaySuccessMessage (
                  this.$t (`saved.${this.operationName}`))

                this.$emit ('unsavedChanges', false)

                this.$emit ('success', {
                  name: this.client.name,
                  id: data.id,
                  groups: this.clientGroups.filter (
                    g => this.client.groups.includes (g.id)).map (g => g.name)
                })
              }
            })
          } else {
            this.$v.$touch ()

            this.$nextTick (function () {
              this.scrollToAlert ()
            })
          }
        })
      },

      vatRateClasses (vatRates, enabled) {
        if (!enabled) {
          return ['col-11', 'col-sm-5']
        } else if (vatRates.length > 1) {
          return ['col-9', 'col-sm-3']
        } else {
          return ['col-10', 'col-sm-4']
        }
      },

      onCancel () {
        this.$emit ('unsavedChanges', false)
        this.$emit ('cancel')
      },

      vatRateLabelErrors (vatRatesObject, idx) {
        const errors = []

        if (!vatRatesObject.$each[idx].name.$dirty) {
          return errors
        }

        if (!vatRatesObject.$each[idx].name.required) {
          errors.push (this.$t ('required.vatRateName'))
        }

        return errors
      },

      vatRateErrors (vatRatesObject, idx) {
        const errors = []

        if (!vatRatesObject.$each[idx].rate.$dirty) {
          return errors
        }

        if (!vatRatesObject.$each[idx].rate.required) {
          errors.push (this.$t ('required.vatRate'))
        }

        if (!vatRatesObject.$each[idx].rate.percentage) {
          errors.push (this.$t ('invalid.vatrate'))
        }

        return errors
      },

      scrollToAlert () {
        goTo ('#invalidDataAlert')
      },

      newTaxObject (obj) {
        const max = obj.reduce ((r, i) => r < i.id ? i.id : r, 0)

        return {id: max + 1, name: '', rate: null}
      }
    }
  }
</script>

<style scoped>
.provider-chain-input input {
  text-align: right;
}
</style>
