<messages>["../ClientCreateUpdate"]</messages>
<template>
  <v-card>
    <v-card-title v-if="groups.length > 0">
      <div>
        <div class="text-h5">
          {{ namesReduced }}
        </div>
        <div
          v-t="'title.permissionsSub'"
          class="cgwng-subheading"/>
      </div>
    </v-card-title>
    <v-card-title
      v-else v-t="'title.noPermissions'"
      class="text-h5"/>

    <v-card-text v-if="groups.length > 0">
      <v-row v-if="loading">
        <v-progress-linear indeterminate/>
      </v-row>
      <template v-else>
        <v-row justify="space-between">
          <v-col
            v-for="perm in subPermissions"
            :key="perm.id"
            cols="12" sm="6" md="4">
            <sub-permission-view
              class="my-4"
              :available="perm.available"
              :denied="perm.denied"
              :granted="perm.granted"
              :forced="perm.forced"
              :show-forced="perm.showForced"
              :title="$t(`label.subsection.${perm.id}`)"/>
          </v-col>
        </v-row>
      </template>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        v-t="'general.button.close'" text
        @click="$emit('close')"/>
    </v-card-actions>
  </v-card>
</template>

<script>
  import {mapActions} from 'vuex'

  import SubPermissionView from './SubPermissionView'

  export default {

    components: {
      SubPermissionView
    },

    props: {
      groups: {
        type: Array,
        required: true
      },
      names: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        loading: true,
        // caching
        permissions: {}
      }
    },

    computed: {
      groupsSorted () {
        return [...this.groups].sort ((a, b) => a >= b).reduce (
          (acc, i) => acc === '' ? i : acc + '-' + i,
          '')
      },

      namesReduced () {
        const reducer = (acc, g) => acc === '' ? g : acc + ', ' + g

        return this.names && this.names.length > 0
          ? this.names.reduce (reducer, '')
          : this.groups.reduce (reducer, '')
      },

      currentValue () {
        if (!this.loading) {
          return this.permissions[this.groupsSorted]
        }
        return null
      },

      subPermissions () {
        return ['Registries', 'OrderTypes', 'Services'].map ((type) => {
          const t = (s) => type === 'Services' && s
            ? s.map (item => ({id: item, value: this.$t (`service.${item}`)}))
            : type === 'Registries' && s
              ? s.map (item => ({
                id: item,
                value: item,
                link:
                  {
                    name: 'registry.view',
                    params: {
                      id: item
                    }
                  }
              }))
              : s ? s.map (item => ({id: item, value: item})) : s

          return {
            id: type,
            available: t (this.currentValue[`available${type}`]),
            granted: t (this.currentValue[`granted${type}`]),
            denied: t (this.currentValue[`denied${type}`]),
            forced: t (this.currentValue[`forced${type}`]),
            showForced: type === 'Services'
          }
        })
      }
    },

    watch: {
      groups (newVal) {
        if (this.groups.length > 0 && !this.permissions[this.groupsSorted]) {
          this.load ()
        }
      }
    },

    created () {
      if (this.groups.length > 0) {
        this.load ()
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      load () {
        this.loading = true

        this.fetchData ({
          op: 'client/data/authorization-groups',
          params: {
            ids: this.groups
          },
          cb: data => {
            console.log (data)
            this.permissions[this.groupsSorted] = data.permissions
          },
          cbFinal: () => {
            this.loading = false
          }
        })
      }
    }
  }
</script>
