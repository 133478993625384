<messages>["../ClientCreateUpdate"]</messages>
<template>
  <v-card flat color="elevation-1" class="ma-1">
    <v-card-title class="text-h5">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <value-block :items="sortedAvailable" :title="$t('label.available')"/>
        </v-col>
        <v-col class="mt-4" cols="12">
          <value-block :items="sortedGranted" :title="$t('label.granted')"/>
        </v-col>
        <v-col class="mt-4" cols="12">
          <value-block :items="sortedDenied" :title="$t('label.denied')"/>
        </v-col>
        <v-col v-if="showForced" class="mt-4" cols="12">
          <value-block :items="sortedForced" :title="$t('label.forced')"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import ValueBlock from '@/app/core/components/ValueBlockShowMore'

  export default {

    components: {
      ValueBlock
    },

    props: {
      available: {
        type: Array,
        default: () => ([])
      },
      denied: {
        type: Array,
        default: () => ([])
      },
      granted: {
        type: Array,
        default: () => ([])
      },
      forced: {
        type: Array,
        default: () => ([])
      },
      showForced: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        required: true
      }
    },

    computed: {
      sortedAvailable () {
        const sorted = [...this.available]
        sorted.sort ((a, b) => {
          return a.value.localeCompare (b.value)
        })
        return sorted
      },
      sortedDenied () {
        const sorted = [...this.denied]
        sorted.sort ((a, b) => {
          return a.value.localeCompare (b.value)
        })
        return sorted
      },
      sortedGranted () {
        const sorted = [...this.granted]
        sorted.sort ((a, b) => {
          return a.value.localeCompare (b.value)
        })
        return sorted
      },
      sortedForced () {
        const sorted = [...this.forced]
        sorted.sort ((a, b) => {
          return a.value.localeCompare (b.value)
        })
        return sorted
      }
    }
  }
</script>
