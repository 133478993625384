<messages>["../ClientCreateUpdate"]</messages>

<template>
  <v-autocomplete
    v-model="selectedGroups"
    :disabled="disabled"
    class="required"
    :label="$t('label.groups')"
    :items="items"
    :persistent-hint="disabled"
    :hint="disabled ?$t ('label.disabledHint') : ''"
    item-text="name"
    item-value="id"
    :error-messages="errorMessages"
    multiple
    clearable
    @blur="blur">
    <template #selection="{ item, parent }">
      <v-chip
        :key="item.id"
        :close="!item.disabled && !disabled"
        outlined
        color="black"
        class="my-1"
        @update:active="parent.selectItem (item)">
        <span
          class="chipContent"
          :title="item.name">
          {{ item.name }}
        </span>
      </v-chip>
    </template>
    <template #item="{item, on, attrs}">
      <v-list-item v-bind="attrs" :disabled="false" v-on="on">
        <v-list-item-action>
          <v-icon :color="selectedGroups.includes(item.id) ? 'primary' : 'default'">
            {{ value.includes(item.id) ? 'check_box' : 'check_box_outline_blank' }}
          </v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title v-html="item.name"/>
        </v-list-item-content>

        <v-list-item-action
          @click.prevent="(e)=>e.stopPropagation()"
          @input.prevent="(e)=>e.stopPropagation()">
          <v-tooltip top>
            <template #activator="{ on: on2 }">
              <v-icon v-on="on2" @click="setDialog([item.id], [item.name])">
                info
              </v-icon>
            </template>
            {{ $t ('label.click') }}
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </template>
    <template
      v-if="!disabled"
      #append-outer>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon v-on="on" @click="setDialog(selectedGroups, selectedNames)">
            info
          </v-icon>
        </template>
        {{ $t ('label.click') }}
      </v-tooltip>
      <base-dialog
        v-model="dialog"
        scrollable
        mw2>
        <client-group-info-card :groups="dialogGroups" :names="dialogNames" @close="dialog = false"/>
      </base-dialog>
    </template>
  </v-autocomplete>
</template>

<script>
  import {mapGetters} from 'vuex'

  import ClientGroupInfoCard from './ClientGroupInfoCard'
  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {
    name: 'ClientGroupInput',

    components: {
      ClientGroupInfoCard,
      BaseDialog
    },

    props: {
      value: {
        type: Array,
        required: true
      },
      isCreate: {
        type: Boolean,
        default: false
      },
      hasParent: {
        type: Boolean,
        default: false
      },
      availableGroups: {
        type: Array,
        required: true
      },
      errorMessages: {
        type: Array,
        required: false,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        dialog: false,
        dialogGroups: [],
        dialogNames: []
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'clientId'
      ]),

      items () {
        const cleared = []

        this.availableGroups.map (g => ({
          ...g,
          disabled: this.isMandatoryGroup (g)
        })).forEach ((item) => {
          if (!cleared.find ((i) => item.id === i.id)) {
            cleared.push (item)
          }
        })
        cleared.sort ((g1, g2) => g1.name.localeCompare (g2.name))

        return cleared
      },

      selectedGroups: {
        get () {
          return this.value
        },
        set (newVal) {
          if (newVal && newVal.length > 0) {
            this.$emit ('input', newVal)
          } else {
            this.$emit ('input', this.getMandatory ())
          }
        }
      },

      selectedNames () {
        return this.selectedGroups.map (
          g => this.availableGroups.find (i => i.id === g).name
        )
      }
    },

    watch: {
      value () {
        this.$forceUpdate ()
      },

      items () {
        // if the mandatory groups change add them to the selected

        const selected = [...new Set ([
          ...this.selectedGroups,
          ...this.items.filter ((g) => g.disabled).map (g => g.id)
        ])]

        this.selectedGroups = selected.filter ((id) => {
          return !this.isCreate || this.availableGroups.map ((i) => i.id).includes (id)
        })
      },

      availableGroups  (newVal) {
        const dialogs = {}
        newVal.forEach ((i) => {
          dialogs[i.id] = false
        })
        this.dialogs = dialogs
      }
    },

    methods: {
      blur () {
        this.$emit ('blur')
      },

      setDialog (ids, names) {
        this.dialogGroups = ids
        this.dialogNames = names
        this.dialog = true
      },

      getMandatory () {
        return this.items.filter ((g) => g.disabled).map (g => g.id)
      },

      isMandatoryGroup (g) {
        const {clientId, effectiveClientId} = this.clientId
        // no parent and clientless current user means no group should be mandatory
        if (!this.hasParent && !effectiveClientId && !clientId) {
          return false
        }

        // contains at least one forced service
        if (g.forcedServices && g.forcedServices.length > 0) {
          return true
        }

        // only contains denied order-types/registries/services
        if (
          (!g.forcedServices || g.forcedServices.length === 0) &&
          (!g.grantedOrderTypes || g.grantedOrderTypes.length === 0) &&
          (!g.grantedRegistries || g.grantedRegistries.length === 0) &&
          (!g.grantedServices || g.grantedServices.length === 0)
        ) {
          return true
        }
        return false
      }
    }
  }
</script>
