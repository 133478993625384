<messages>["./CurrencyConfig"]</messages>

<template>
  <v-card>
    <form @submit.prevent="onSubmit">
      <v-card-title
        v-t="`input.title.${mode}`"
        class="text-h5"/>

      <v-card-text>
        <currency-select
          ref="from"
          :v="$v.from"
          required
          :disabled="mode === 'edit'"
          :label="$t ('label.from')"
          :frequently-used="frequentlyUsed"
          :additional-items="additionalCurrencies"
          :exclude="excludeFrom.filter (it => it !== from)"/>
        <v-switch
          v-model="doConversion"
          :disabled="isConversionDisabled"
          :label="$t ('label.doConversion')"/>
        <currency-select
          v-if="doConversion"
          ref="to"
          :v="$v.to"
          required
          :label="$t ('label.to')"
          :exclude="[from, ...excludeTo.filter (it => it !== value.to)]"/>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.cancel'"
          text
          @click.native="onCancel"/>
        <v-btn
          v-t="'general.button.ok'"
          type="submit"
          color="primary"/>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
  import {required, requiredIf} from 'vuelidate/lib/validators'

  import CurrencySelect, {FrequentlyUsedCurrencies}
    from '@/app/core/components/CurrencySelect'

  export default {
    name: 'CurrencyMappingInput',

    components: {CurrencySelect},

    props: {
      value: {
        type: Object,
        default: () => ({})
      },
      excludeFrom: {
        type: Array,
        default: () => []
      },
      excludeTo: {
        type: Array,
        default: () => []
      },
      // items, which can appear in `from`, but not in `to`
      // ("unconvert this currency" option)
      fromOnly: {
        type: Array,
        default: () => []
      }
    },

    data () {
      return {
        from: null,
        doConversion: false,
        to: null
      }
    },

    validations: {
      from: {required},
      to: {
        required: requiredIf (function () {
          return this.doConversion
        })
      }
    },

    computed: {
      mode () {
        return this.value?.from && this.value?.to ? 'edit' : 'create'
      },

      isConversionDisabled () {
        return this.fromOnly.includes (this.from)
      },

      frequentlyUsed () {
        return ['*', ...FrequentlyUsedCurrencies]
      },

      additionalCurrencies () {
        return [{
          text: this.$t ('label.mapAll', {value: '*'}),
          value: '*'
        }]
      }
    },

    watch: {
      isConversionDisabled (newValue) {
        if (newValue) {
          this.doConversion = false
        } else {
          this.doConversion = this.from && this.from !== this.to
        }
      },

      value: {
        handler (newValue) {
          this.from = newValue.from || ''
          this.to = newValue.to || ''
          this.doConversion = newValue.from && newValue.from !== newValue.to
          this.$v.$reset ()
          this.$nextTick (() => {
            this.$refs.from.focus ()
          })
        },
        immediate: true
      }
    },

    methods: {
      /**
       * handler for 'cancel' button
       */
      onCancel () {
        this.$emit ('cancel', {
          from: this.from,
          to: this.to
        })
      },

      /**
       * handler for 'ok' button
       */
      onSubmit () {
        this.$v.$touch ()

        if (!this.$v.$invalid) {
          this.$emit (
            'input', {
              from: this.from,
              to: this.doConversion ? this.to : this.from
            })
        }
      }
    }
  }
</script>
