var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(!_vm.noTitle)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t ('list.title'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" help ")])]}}],null,false,2823180607)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(("label.infoText." + (_vm.disabled ?'disabled' : 'active'))),expression:"`label.infoText.${disabled ?'disabled' : 'active' }`"}]})])],1),_c('v-col',{attrs:{"cols":"auto"}},[(!_vm.disabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({on:{"click":_vm.onAdd}},on),[_vm._v(" "+_vm._s(_vm.$t ('general.button.new'))+" "),_c('v-icon',{staticClass:"pl-2"},[_vm._v(" add ")])],1)]}}],null,false,1313964718)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('label.addToolTip'),expression:"'label.addToolTip'"}]})]):_vm._e()],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.mappingsVisual,"items-per-page":-1,"hide-default-footer":"","custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"headerCell",fn:function(props){return [_vm._v(" "+_vm._s(props.header.text)+" ")]}},{key:"item",fn:function(props){return [_c('tr',{class:{default: _vm.isDefaultCurrency (props.item)}},[_c('td',{domProps:{"textContent":_vm._s(_vm.formatCurrencyExt (props.item.from))}}),_c('td',{staticClass:"text-left"},[(_vm.isItemStateChecked (props.item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success","large":""}},on),[_vm._v(" check ")])]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('label.keep'),expression:"'label.keep'"}]})]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"arrowTo"},on),[_vm._v(" trending_flat ")])]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('label.convert'),expression:"'label.convert'"}]})])],1),_c('td',{staticClass:"currencyState"},[_c('span',[_vm._v(" "+_vm._s(_vm.formatTo (props.item))+" ")])]),(!_vm.disabled)?_c('td',[_c('action-buttons',{attrs:{"value":_vm.isActionButtonsActive (props.item.from),"buttons":_vm.getActionButtons (props)},on:{"input":function (state) { return _vm.setActionButtonsActive (props.item.from) (state); },"clicked":_vm.processActionButton}})],1):_vm._e()])]}}])}),_c('base-dialog',{attrs:{"mw0":"","persistent":"","close-on-esc":""},model:{value:(_vm.isFormVisible),callback:function ($$v) {_vm.isFormVisible=$$v},expression:"isFormVisible"}},[_c('currency-mapping-input',{attrs:{"value":_vm.inputValue,"exclude-from":_vm.excludeFrom,"exclude-to":_vm.excludeTo,"from-only":_vm.fromOnly},on:{"input":_vm.onInputValueChange,"cancel":function($event){_vm.isFormVisible = false}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }