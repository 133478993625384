<messages>["./CurrencyConfig"]</messages>

<template>
  <v-row>
    <v-col v-if="!noTitle" cols="12">
      <v-row no-gutters>
        <v-col class="text-subtitle-1">
          {{ $t ('list.title') }}
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon v-on="on">
                help
              </v-icon>
            </template>
            <span v-t="`label.infoText.${disabled ?'disabled' : 'active' }`"/>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-tooltip v-if="!disabled" bottom>
            <template #activator="{ on }">
              <v-btn
                v-on="on"
                @click="onAdd">
                {{ $t ('general.button.new') }}
                <v-icon class="pl-2">
                  add
                </v-icon>
              </v-btn>
            </template>
            <span v-t="'label.addToolTip'"/>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="mappingsVisual"
          :items-per-page="-1"
          hide-default-footer
          class="elevation-1"
          :custom-sort="customSort">
          <template #headerCell="props">
            {{ props.header.text }}
          </template>
          <template #item="props">
            <tr :class="{default: isDefaultCurrency (props.item)}">
              <td v-text="formatCurrencyExt (props.item.from)"/>
              <td class="text-left">
                <v-tooltip
                  v-if="isItemStateChecked (props.item)"
                  bottom>
                  <template #activator="{ on }">
                    <v-icon
                      color="success"
                      large
                      v-on="on">
                      check
                    </v-icon>
                  </template>
                  <span v-t="'label.keep'"/>
                </v-tooltip>
                <v-tooltip
                  v-else
                  bottom>
                  <template #activator="{ on }">
                    <v-icon class="arrowTo" v-on="on">
                      trending_flat
                    </v-icon>
                  </template>
                  <span v-t="'label.convert'"/>
                </v-tooltip>
              </td>
              <td class="currencyState">
                <span>
                  {{ formatTo (props.item) }}
                </span>
              </td>
              <td v-if="!disabled">
                <action-buttons
                  :value="isActionButtonsActive (props.item.from)"
                  :buttons="getActionButtons (props)"
                  @input="state => setActionButtonsActive (props.item.from) (state)"
                  @clicked="processActionButton"/>
              </td>
            </tr>
          </template>
        </v-data-table>
        <base-dialog
          v-model="isFormVisible"
          mw0
          persistent
          close-on-esc>
          <currency-mapping-input
            :value="inputValue"
            :exclude-from="excludeFrom"
            :exclude-to="excludeTo"
            :from-only="fromOnly"
            @input="onInputValueChange"
            @cancel="isFormVisible = false"/>
        </base-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import _cloneDeep from 'lodash/cloneDeep'

  import {EmptyMark} from '@/app/utils/string'

  import BaseDialog from '@/app/core/components/BaseDialog'

  import CurrencyMappingInput
    from '@/app/pages/Client/components/CurrencyMappingInput'
  import ActionButtons from '@/app/core/components/ActionButtons'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'

  export const WILDCARD_CURRENCIES_MARK = '*'

  export default {
    name: 'CurrencyConfig',

    components: {ActionButtons, CurrencyMappingInput, BaseDialog},

    mixins: [
      actionButtonsHelper
    ],

    props: {
      value: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      noTitle: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        mappings: null,
        isFormVisible: false,
        inputIndex: -1,
        inputValue: null
      }
    },

    computed: {
      headers () {
        const headerItem = it => ({
          text: this.$t (`label.${it}`),
          sortable: false
        })

        const headers = ['sourceCurrency', 'keepConvert', 'desitnationCurrency'].map (headerItem)

        if (!this.disabled) {
          headers.push ({
            text: this.$t ('general.label.actions'),
            sortable: false
          })
        }

        headers[0].value = 'from'
        headers[0].sortable = true

        return headers
      },

      emptyMark () {
        return EmptyMark
      },

      isEmpty () {
        return this.mappingsVisual && this.mappingsVisual.length > 1
      },

      mappingsVisual () {
        const mappingsVisual = []
        const m = this.mappings
        const defIdx = m.findIndex (it => it.from === WILDCARD_CURRENCIES_MARK)

        if (defIdx >= 0) {
          mappingsVisual.push (
            ...m.slice (0, defIdx), ...m.slice (defIdx + 1), m[defIdx])
        } else {
          mappingsVisual.push (
            ...m,
            {from: WILDCARD_CURRENCIES_MARK, to: WILDCARD_CURRENCIES_MARK})
        }

        return mappingsVisual
      },

      fromOnly () {
        const result = new Set ()

        this.mappings.forEach (it => {
          result.add (it.to)
          result.delete (it.from)
        })

        return [...result]
      },

      excludeFrom () {
        const excl = new Set ()

        const mappings = this.mappings
        const inputIndex = this.inputIndex

        mappings.forEach (it => {
          excl.add (it.from)
          if (it.from === WILDCARD_CURRENCIES_MARK &&
            it.to !== WILDCARD_CURRENCIES_MARK) {
            excl.add (it.to)
          }
        })

        if (inputIndex >= 0 && mappings.length >= inputIndex + 1) {
          excl.delete (mappings[inputIndex].from)
        }

        return [...excl]
      },

      excludeTo () {
        const excl = new Set ()

        this.mappings.forEach (it => {
          if (it.from !== it.to) {
            excl.add (it.from)
          }
          // prevent conversion to default currency
          if (it.from === WILDCARD_CURRENCIES_MARK &&
            it.to !== WILDCARD_CURRENCIES_MARK) {
            excl.add (it.to)
          }
        })

        return [...excl]
      }
    },

    watch: {
      value: {
        handler () {
          this.mappings = _cloneDeep (this.value)
        },
        immediate: true
      }
    },

    methods: {
      customSort (items, index, isDescending) {
        items.sort ((a, b) => {
          let res

          if (a.from === WILDCARD_CURRENCIES_MARK) {
            res = 1
          } else {
            if (b.from === WILDCARD_CURRENCIES_MARK) {
              res = -1
            } else {
              res = a.from === b.from ? 0 : a.from > b.from ? 1 : -1
              res = isDescending ? -1 * res : res
            }
          }

          return res
        })

        return items
      },

      isDefaultCurrency (item) {
        return item.from === WILDCARD_CURRENCIES_MARK
      },

      formatCurrencyExt (value) {
        return value === WILDCARD_CURRENCIES_MARK
          ? this.isEmpty
            ? this.$t ('label.mapAllOther', {value})
            : this.$t ('label.mapAll', {value})
          : this.formatCurrency (value)
      },

      isItemStateChecked (value) {
        return value.from === value.to
      },

      formatTo (value) {
        if (value.to === value.from) {
          return EmptyMark
        }
        return this.formatCurrency (value.to)
      },

      /**
       * calculate action buttons for specified item
       *
       * @param item      item in the list for which action buttons should be
       *                  calculated
       * @param index     item index
       */
      getActionButtons ({item, index}) {
        return [
          {
            action: 'edit',
            item,
            index,
            icon: 'edit',
            tooltip: this.$t ('general.button.edit')
          },
          ...(
            item.from === WILDCARD_CURRENCIES_MARK
              ? []
              : [{
                action: 'delete',
                item,
                icon: 'delete',
                tooltip: this.$t ('general.button.delete'),
                color: 'error'
              }])
        ]
      },

      /**
       * process specified action button according to it's properties
       *
       * @param button {Object}     button to be processed
       */
      processActionButton (button) {
        switch (button.action) {
          case 'delete':
            this.onDelete (button.item)
            break

          case 'edit':
            this.onEdit (button.item, button.index)
            break

          default:
            console.error (
              `"processActionButton": the action '${button.action}' is unknown`)
            break
        }
      },

      /**
       * handler for adding new currency conversion mapping
       */
      onAdd () {
        this.onEdit ({}, -1)
      },

      /**
       * handler for currency conversion mapping deletion
       */
      onDelete (item) {
        const index = this.mappings.findIndex (it =>
          it.from === item.from && it.to === item.to)

        this.mappings.splice (index, 1)
        this.$emit ('input', this.mappings)
      },

      /**
       * handler for edit currency conversion mapping
       */
      onEdit (item, index) {
        this.inputIndex = index
        this.inputValue = item
        this.isFormVisible = true
      },

      /**
       * handler to be called after currency conversion mapping change
       */
      onInputValueChange (newValue) {
        this.isFormVisible = false

        const idx = this.mappings.findIndex (it => it.from === newValue.from)

        if (idx >= 0) {
          this.$set (this.mappings, idx, newValue)
        } else {
          this.mappings.push (newValue)
        }

        this.$emit ('input', this.mappings)
      }
    }
  }
</script>

<style scoped>
.default td {
  font-weight: bold;
}

td.currencyState {
}

.arrowTo {
  display: inline-block;
  vertical-align: bottom;
}
</style>
